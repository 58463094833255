import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import classes from "./tech4dev.module.css"
import generalClasses from "./general.module.css"

import tech4devTilted from "../../images/case-studies/tech4dev-1.jpg"
import tech4devFull from "../../images/case-studies/tech4dev-full.jpg"
import tech4devLaptop from "../../images/case-studies/laptop-tech4dev.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"

import RightIcon from "../../images/arrow-right.inline.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"

const Tech4Dev = props => (
  <HomePageLayout pageTitle="Tech4dev" background>
    <SEO title="Tech4dev Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>TECH4DEV</h1>
          <p className={generalClasses.caseStudyDescription}>
            Tech4Dev is a website that was designed for an NGO.
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          <a
            href="https://tech4dev.com"
            target="_blank"
            rel="noopener noreferrer"
            className={generalClasses.caseStudyLink}
          >
            VISIT WEBSITE
          </a>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Tech4Dev Initiative
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Lagos, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent} id="color">
              500,000 - 100,000,000 users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Non-profit</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>MEET TECH4DEV</h1>
        <p
          className={[
            generalClasses.meetCaseStudyText,
            classes.meetCaseStudyText,
          ].join(" ")}
        >
          Technology for Social Change and Development Initiative is a
          non-profit organization founded in 2016. Tech4Dev was established to
          empower African communities with digital skills that will bridge the
          gap of unskilled labour needed for the future of work. We have over
          the years, implemented projects that inspire, advocate, and train
          people on basic digital skills, programming skills, and essential
          skills needed for the future of work.
        </p>
      </section>
      <img
        src={tech4devTilted}
        className={generalClasses.displayImage}
        alt=""
      />
      <section
        className={[generalClasses.grid, generalClasses.section].join(" ")}
      >
        <div>
          <h1 className={generalClasses.sectionHeader}>RESEARCH</h1>
          <p className={generalClasses.sectionText}>
            A proper research was carried out before the commencement of this
            project for Tech4Dev. Our Design Team went out took into
            consideration that the website to be built is for an NGO and carried
            out the proper measures in interviewing the candidates of their
            initiatives and also sending out surveys to potential users to find
            out what exactly they would love to see on a website and also their
            pain points faced during usage of other NGO websites. After
            collating the results from the research, we came to a conclusion and
            came up with a sketch for the websites which was also tested by the
            users to know if it met their goals.
          </p>
        </div>
        <div>
          <h1 className={generalClasses.sectionHeader}>RESULTS</h1>
          <p className={generalClasses.sectionText}>
            A proper research was carried out before the commencement of this
            project for Tech4Dev. Our Design Team went out took into
            consideration that the website to be built is for an NGO and carried
            out the proper measures in interviewing the candidates of their
            initiatives and also sending out surveys to potential users to find
            out what exactly they would love to see on a website and also their
            pain points faced during usage of other NGO websites. After
            collating the results from the research, we came to a conclusion and
            came up with a sketch for the websites which was also tested by the
            users to know if it met their goals.
          </p>
        </div>
      </section>
      <img src={tech4devFull} alt="" className={classes.tech4devImageFull} />
      <img
        src={tech4devLaptop}
        alt=""
        className={generalClasses.displayImageFull}
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/lms"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>LMS</span>
          </p>
        </Link>
        <Link
          to="/case-studies/odirs"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Ondo State Internal Revenue Service
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default Tech4Dev
